/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* custom styles */
:root {
    --yellowProsegur: #ffd102;
    --black: #000000;
    --white: #ffffff;
    --red: #FF0000;
    --green: #00b347;
    --secondary1: #637A7C;
    --secondary2: #78CBCF;
    --secondary3: #F3813C;
    --secondary4: #9D9FA2;
    --linkHover: #548088;
    --linkDisabled: #D2D1D1;
}
body {
    overflow-x: hidden;
}
app-header,
app-footer,
app-login-user {
    display: flow-root;
}
app-header {
    height: 75px;
}
.container-max {
    max-width: inherit;
}
.container-90-per {
    max-width: 90%;
    margin: 0 auto 0;
}
.container-90-per-pad {
    max-width: inherit;
    margin: 0 0 48px 0;
    padding: 0 5% 0;
    position: relative;
}
.row--center-all {
    justify-content: center;
    align-content: center;
}

// fonts
// Poppins-Regular
@font-face {
    font-family: "Poppins";
    src: local("Poppins"), url(./assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
// Poppins-italic
@font-face {
    font-family: "Poppins-italic";
    src: local("Poppins-italic"), url(./assets/fonts/Poppins/Poppins-Italic.ttf) format("truetype");
}
// Poppins-Black
@font-face {
    font-family: "Poppins-black";
    src: local("Poppins-black"), url(./assets/fonts/Poppins/Poppins-Black.ttf) format("truetype");
}
// Poppins-Black-italic
@font-face {
    font-family: "Poppins-black-italic";
    src: local("Poppins-black-italic"), url(./assets/fonts/Poppins/Poppins-BlackItalic.ttf) format("truetype");
}
// Poppins-Bold
@font-face {
    font-family: "Poppins-bold";
    src: local("Poppins-bold"), url(./assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
// Poppins-Bold-italic
@font-face {
    font-family: "Poppins-bold-italic";
    src: local("Poppins-bold-italic"), url(./assets/fonts/Poppins/Poppins-BoldItalic.ttf) format("truetype");
}
// Poppins-ExtraBold
@font-face {
    font-family: "Poppins-extra-bold";
    src: local("Poppins-extra-bold"), url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");
}
// Poppins-ExtraBold-italic
@font-face {
    font-family: "Poppins-extra-bold-italic";
    src: local("Poppins-extra-bold-italic"), url(./assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format("truetype");
}
// Poppins-ExtraLight
@font-face {
    font-family: "Poppins-extra-light";
    src: local("Poppins-extra-light"), url(./assets/fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
}
// Poppins-ExtraLight-italic
@font-face {
    font-family: "Poppins-extra-light-italic";
    src: local("Poppins-extra-light-italic"), url(./assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf) format("truetype");
}
// Poppins-light
@font-face {
    font-family: "Poppins-light";
    src: local("Poppins-light"), url(./assets/fonts/Poppins/Poppins-Light.ttf) format("truetype");
}
// Poppins-light-italic
@font-face {
    font-family: "Poppins-light-italic";
    src: local("Poppins-light-italic"), url(./assets/fonts/Poppins/Poppins-LightItalic.ttf) format("truetype");
}
// Poppins-medium
@font-face {
    font-family: "Poppins-medium";
    src: local("Poppins-medium"), url(./assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
// Poppins-medium-italic
@font-face {
    font-family: "Poppins-medium-italic";
    src: local("Poppins-medium-italic"), url(./assets/fonts/Poppins/Poppins-MediumItalic.ttf) format("truetype");
}
// Poppins-semiBold
@font-face {
    font-family: "Poppins-semi-bold";
    src: local("Poppins-semi-bold"), url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}
// Poppins-semiBold-italic
@font-face {
    font-family: "Poppins-semi-bold-italic";
    src: local("Poppins-semi-bold-italic"), url(./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf) format("truetype");
}
// Poppins-thin
@font-face {
    font-family: "Poppins-thin";
    src: local("Poppins-thin"), url(./assets/fonts/Poppins/Poppins-Thin.ttf) format("truetype");
}
// Poppins-thim-italic
@font-face {
    font-family: "Poppins-thin-italic";
    src: local("Poppins-thin-italic"), url(./assets/fonts/Poppins/Poppins-ThinItalic.ttf) format("truetype");
}

/* rewrite styles prime */
.p-breadcrumb ul li .p-menuitem-link:focus,
.p-menu .p-menuitem-link:focus,
.p-slidemenu .p-menuitem-link:focus,
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus,
.p-button:focus,
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus,
.p-datatable .p-sortable-column:focus {
    box-shadow: none !important;
}
:host ::ng-deep {
    * {
        font-family: "Poppins" !important;
    }
}
* {
    font-family: "Poppins" !important;
    &.pi {
        font-family: "primeicons" !important;
    }
}

.p-button.p-button {
  transition: all 0.1s;
  &--primary--hover-none {
      color: var(--black);
      background: var(--yellowProsegur);
      border: 1px solid var(--yellowProsegur);
      transition: all 0.1s;
      &:enabled:hover {
        color: var(--black);
        background: var(--yellowProsegur);
        border: 1px solid var(--yellowProsegur);
        transition: all 0.1s;
      }
  }
  &--primary,&.p-button-info, &.p-button-text,
  &.p-datepicker-trigger {
      color: var(--black);
      background: var(--yellowProsegur);
      border: 1px solid var(--yellowProsegur);
      transition: all 0.1s;
      &:enabled:hover {
          color: var(--white);
          background: var(--black);
          border: 1px solid var(--black);
          transition: all 0.1s;
      }
  }
  &--secondary {
      color: var(--black);
      background: transparent;
      border: 1px solid var(--black);
      transition: all 0.1s;
      &:enabled:hover {
          color: var(--black);
          background: var(--yellowProsegur);
          border: 1px solid var(--yellowProsegur);
          transition: all 0.1s;
      }
  }
  &--terciary {
      color: var(--black);
      background: transparent;
      border: 1px solid var(--yellowProsegur);
      transition: all 0.1s;
      &:enabled:hover {
          color: var(--black);
          background: var(--yellowProsegur);
          border: 1px solid var(--yellowProsegur);
          transition: all 0.1s;
      }
  }
  &:disabled {
      color: var(--black);
      background: var(--linkDisabled);
      border: 1px solid var(--linkDisabled);
      transition: all 0.1s;
  }
}

p-breadcrumb {
    display: block;
    margin-top: 75px;
    padding: 0 5% 0;
    background-color: var(--black);
    position: fixed;
    width: 100%;
    z-index: 1100;
    .p-breadcrumb {
        border: 0 !important;
        background-color: var(--black);
        ul {
            li {
                &:last-child {
                    font-weight: bold;
                }
                &.p-element.current {
                    border-bottom: 3px solid var(--yellowProsegur);
                }
                .p-menuitem-link {
                    .p-menuitem-icon {
                        color: var(--white);
                    }
                    .p-menuitem-text {
                        color: var(--white);
                    }
                }
            }
        }
    }
}
/*AMS- refactorizar*/
p-accordion{
    .p-accordion-content {
        background: #e7e7e7 !important;
    }
    .p-accordion-header .p-accordion-header-link {
        background: var(--yellowProsegur) !important;
    }
    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: #757575 !important;
        color: var(--white) !important;
    }
}
.p-column-filter-row p-columnfilterformelement {
    min-width: 58%;
}
.p-calendar {
    .p-inputtext {
        margin-bottom: 0px !important;
    }
}
.p-checkbox {
    margin-right: 5px;
    .p-checkbox-box{
        &.p-highlight {
            border-color: var(--yellowProsegur) !important;
            background: var(--yellowProsegur) !important;
        }
        .p-checkbox-icon {
            color: var(--black) !important;
        }
    }
}
.p-radiobutton {
    .p-radiobutton-box {
        &.p-highlight {
            border-color: var(--yellowProsegur) !important;
            background: var(--yellowProsegur) !important;
        }
    }
}
.p-inputnumber-input {
    width: 100%;
}
p-inputmask {
    &.col-md-1 {
        input {
            width: 8.33333333%;
        }
    }
    &.col-md-2 {
        input {
            width: 16.66666666%;
        }
    }
}
.p-dropdown {
    max-width: 100%;
}
.p-toast {
    z-index: 1104 !important;
}

.p-button {
    transition: all 0.1s;
    &--primary,
    &.p-datepicker-trigger {
        color: var(--black) !important;
        background: var(--yellowProsegur) !important;
        border: 1px solid var(--yellowProsegur) !important;
        transition: all 0.1s;
        &:enabled:hover {
            color: var(--white) !important;
            background: var(--black) !important;
            border: 1px solid var(--black) !important;
            transition: all 0.1s;
        }
    }
    &--secondary {
        color: var(--black) !important;
        background: transparent !important;
        border: 1px solid var(--black) !important;
        transition: all 0.1s;
        &:enabled:hover {
            color: var(--black) !important;
            background: var(--yellowProsegur) !important;
            border: 1px solid var(--yellowProsegur) !important;
            transition: all 0.1s;
        }

    }
    &--terciary {
        color: var(--black) !important;
        background: transparent !important;
        border: 1px solid var(--yellowProsegur) !important;
        transition: all 0.1s;
        &:enabled:hover {
            color: var(--black) !important;
            background: var(--yellowProsegur) !important;
            border: 1px solid var(--yellowProsegur) !important;
            transition: all 0.1s;
        }
    }
    &--disabledRed {
      color: var(--white) !important;
      background: var(--red) !important;
      border: 1px solid var(--linkDisabled) !important;
      transition: all 0.1s;
      opacity: 1 !important;
      &:disabled {
        color: var(--white) !important;
        background: var(--red) !important;
        border: 1px solid var(--linkDisabled) !important;
        transition: all 0.1s;
        opacity: 1 !important;
    }
  }
    &--disabledGreen {
      color: var(--white) !important;
      background: var(--green) !important;
      border: 1px solid var(--linkDisabled) !important;
      transition: all 0.1s;
      opacity: 1 !important;
      &:disabled {
        color: var(--white) !important;
        background: var(--green) !important;
        border: 1px solid var(--linkDisabled) !important;
        transition: all 0.1s;
        opacity: 1 !important;
    }
  }
  &--disabled {
    color: var(--black) !important;
    background: var(--linkDisabled) !important;
    border: 1px solid var(--linkDisabled) !important;
    transition: all 0.1s;
    &:enabled:hover {
      color: var(--black) !important;
      background: var(--secondary1) !important;
      border: 1px solid var(--secondary1) !important;
      transition: all 0.1s;
  }
 }
}


p-progressspinner {
    position: absolute;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-content: center;
    justify-content: center;
    .p-progress-spinner {
        top: calc(50% - 50px);
    }
}

.progress-spinner {
    position: fixed;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}


p-card {
    display: flex;
}

a {
    color: var(--black) !important;
    text-decoration: none !important;
    &:hover {
        color: var(--linkHover) !important;
    }
}

h1 {
    padding: 15px 0 25px;
}



:host ::ng-deep .p-button {
  margin: 0 .5rem 0 0;
  min-width: 10rem;
}

p {
  margin: 0;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

:host ::ng-deep .p-dialog .p-button {
  min-width: 6rem;
}


button[id="download"],
button[id="download2"],
button[id="print"],
button[id="print2"] {
  display: none !important;
}

.unverified-signature-warning {
  display: none;
}

@media (max-width: 360px) {
  body::-webkit-scrollbar, .preview-document-dialog::-webkit-scrollbar {
    display: none;
  }

  body, .preview-document-dialog {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .p-dialog-content,.p-dialog-titlebar {
    padding: 0px !important;
    overflow: hidden;
  }
  .p-dialog-title  {
    font-size: 0.8rem !important;
  }
}
